import { fetcher } from '../fetcher';

export const authService = {
  login: async (payload: LoginForm) => {
    return await fetcher(`/login`, {
      method: 'POST',
      body: payload,
    });
  },

  sendLoginCode: async (payload: LoginCodeForm) => {
    return await fetcher(`/login-code`, {
      method: 'POST',
      body: {
        ...payload,
        code: parseInt(payload.code),
      },
      schema: tokensDataSchema,
    });
  },

  sendTokenPassword(payload: SendTokenPasswordForm) {
    return fetcher(`/send-token-password`, {
      method: 'POST',
      body: payload,
    });
  },

  logout: async () => {
    return await fetcher(`/logout`, {
      method: 'POST',
    });
  },

  resetPassword: async (payload: ResetPasswordForm) => {
    return await fetcher(`/reset-password`, {
      method: 'POST',
      body: payload,
    });
  },
};
