<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="114"
    height="36"
    viewBox="0 0 114 36"
    fill="none"
  >
    <g id="logo">
      <g id="Vector">
        <path
          d="M55.0231 35.8576C51.621 35.4103 48.6527 34.1227 46.6195 32.198C45.481 31.1136 45.1963 30.5444 45.4674 29.8937C45.8198 29.0669 46.9448 28.457 47.9207 28.5925C48.5984 28.6874 49.3168 29.0669 50.6858 30.0428C52.868 31.6287 54.2234 32.076 57.1105 32.1709C60.4042 32.2928 62.0307 31.832 64.7686 29.9886C66.7204 28.6739 66.978 28.5519 67.7912 28.5519C69.0924 28.5519 70.1361 29.4058 70.1361 30.4766C70.1361 31.8456 66.612 34.3124 63.3319 35.2477C60.9192 35.9389 57.5984 36.1829 55.0231 35.8576Z"
          fill="url(#paint0_linear_6001_2185)"
        />
        <path
          d="M1.72798 25.5157C1.484 25.4073 1.1587 25.1091 1.0096 24.8651C0.73852 24.4314 0.73852 24.2552 0.765628 16.8546L0.802636 9.95887C0.804967 9.52456 0.987995 9.11082 1.3078 8.81695C1.74153 8.42388 1.91774 8.3561 2.4599 8.3561C3.2325 8.3561 3.8831 8.70851 4.19484 9.3049C4.3846 9.65731 4.39816 10.6061 4.37105 17.1663L4.33415 23.9311C4.33174 24.3732 4.15505 24.7965 3.84243 25.1091C3.2596 25.6784 2.44635 25.841 1.72798 25.5157Z"
          fill="url(#paint1_linear_6001_2185)"
        />
        <path
          d="M9.94183 25.3938C9.00659 24.7703 8.99303 24.6618 9.04725 19.8094C9.10147 15.0112 9.14213 14.7944 10.0367 12.9781C10.7415 11.5549 12.3545 9.9284 13.7506 9.26424C15.1466 8.58653 16.1632 8.34255 17.6813 8.34255C20.1346 8.34255 21.9915 9.11514 23.7129 10.8501C24.8786 12.0293 25.4478 12.9645 25.9629 14.5233C26.2475 15.4179 26.2747 15.7296 26.3153 19.8094C26.3695 23.7672 26.3424 24.1874 26.1256 24.6212C25.6105 25.6106 24.4448 25.9766 23.5503 25.4344C22.6286 24.8787 22.6421 24.8922 22.5608 20.0805L22.5064 16.5979C22.4976 16.0346 22.3682 15.4797 22.1271 14.9706C21.4358 13.4796 20.1753 12.4766 18.5759 12.1513C16.4072 11.704 14.225 12.8697 13.2355 14.9706C12.9943 15.4797 12.865 16.0346 12.8562 16.5979L12.8018 20.0805C12.7476 23.9706 12.7069 24.4721 12.49 24.7974C11.9072 25.6919 10.7686 25.9495 9.94183 25.3938Z"
          fill="url(#paint2_linear_6001_2185)"
        />
        <path
          d="M36.0472 25.4886C34.109 25.082 31.8319 23.591 31.1542 22.2763C30.8289 21.6663 30.8695 20.7989 31.2355 20.2838C32.0352 19.1724 33.5397 19.213 34.4072 20.3922C35.1527 21.4224 36.3048 21.9103 37.9719 21.9103C39.5849 21.9103 40.859 21.3004 40.859 20.5413C40.859 19.9043 39.7882 19.4163 37.2942 18.9419C34.1632 18.3456 32.2249 17.2206 31.371 15.5398C30.4493 13.7236 30.9102 11.46 32.5231 10.0097C33.7701 8.88472 35.3831 8.3561 37.5382 8.3561C39.978 8.3561 41.984 9.08803 43.4343 10.5248C44.5864 11.6498 44.9252 12.5986 44.4644 13.4796C43.7867 14.7808 42.2957 14.8892 41.3063 13.71C40.6286 12.8968 39.9509 12.463 38.9207 12.2055C36.7521 11.6362 34.1903 12.6392 34.7325 13.8456C34.9629 14.3471 36.2641 14.9028 38.0804 15.2688C41.7671 16.0142 43.4343 16.9224 44.2746 18.6302C44.5322 19.1588 44.5864 19.4841 44.5864 20.4871C44.5864 21.5444 44.5322 21.8019 44.2069 22.466C43.0141 24.9194 39.5171 26.207 36.0472 25.4886Z"
          fill="url(#paint3_linear_6001_2185)"
        />
        <path
          d="M56.7852 25.6242C54.9825 25.2447 53.5728 24.5398 52.3665 23.4284C51.2957 22.4389 50.5367 21.3004 49.9945 19.8772C49.7434 19.2046 49.6107 18.4935 49.6023 17.7756L49.5608 14.2522L49.5251 10.2114C49.5219 9.84133 49.6286 9.47864 49.8319 9.16936C50.2385 8.57297 50.6587 8.3561 51.4855 8.3561C52.0683 8.3561 52.2039 8.41032 52.6647 8.88472C53.0033 9.22332 53.1943 9.68205 53.1961 10.1609L53.2069 13.0459C53.2204 17.1392 53.2475 17.6272 53.5457 18.576C53.9388 19.8365 54.7656 20.7853 56.0939 21.4766C56.7174 21.8019 56.9343 21.8425 58.1406 21.8425C59.3469 21.8425 59.5774 21.8019 60.2686 21.463C61.2581 20.9751 62.2882 19.8501 62.6948 18.82C62.993 18.088 63.0201 17.8169 63.0879 13.7507C63.1557 9.67086 63.1692 9.44044 63.4403 9.07448C63.8198 8.55942 64.2536 8.3561 65.0126 8.3561C65.487 8.3561 65.7445 8.43743 66.0427 8.69496C66.7475 9.29135 66.7747 9.42689 66.7204 14.3335C66.6662 19.1724 66.6391 19.3621 65.8665 20.9751C65.7117 21.2933 65.7039 21.6634 65.8451 21.9879L66.124 22.6287C66.6933 23.9706 66.8695 24.9194 66.5984 25.2447C66.3274 25.5835 65.4192 25.3938 64.1858 24.7567C63.5216 24.4161 62.7345 24.4141 62.0686 24.7512L62.0578 24.7567C61.4614 25.0549 60.5533 25.3938 60.0382 25.5022C59.1572 25.6919 57.4222 25.7462 56.7852 25.6242Z"
          fill="url(#paint4_linear_6001_2185)"
        />
        <path
          d="M72.8334 25.3937C71.8439 24.7431 71.8981 25.3802 71.8981 13.466C71.8981 4.66936 71.9252 2.71755 72.0879 2.33803C72.6436 1.00972 74.5412 0.860625 75.3816 2.09406C75.612 2.44647 75.6255 3.04285 75.6255 13.466C75.6255 24.3772 75.6255 24.4721 75.3409 24.8516C75.0021 25.326 74.2701 25.7055 73.7144 25.7055C73.484 25.7055 73.0909 25.57 72.8334 25.3937Z"
          fill="url(#paint5_linear_6001_2185)"
        />
        <path
          d="M82.3349 25.4615C82.0774 25.3395 81.7521 25.0142 81.5894 24.7567C81.3319 24.2959 81.3183 24.0383 81.2777 17.2612C81.2505 12.6799 81.2912 10.0639 81.3861 9.72508C81.4674 9.41333 81.7114 9.02026 81.996 8.77629C82.3891 8.43743 82.5924 8.3561 83.121 8.3561C83.5141 8.3561 83.9207 8.46454 84.2054 8.62719C85.0457 9.14225 85.0457 9.08803 85.0457 17.0579C85.0457 24.0248 85.0322 24.2959 84.7746 24.7296C84.2189 25.6242 83.243 25.9224 82.3349 25.4615Z"
          fill="url(#paint6_linear_6001_2185)"
        />
        <path
          d="M96.7023 25.5835C93.9373 25.0549 91.5789 23.2251 90.4132 20.6633C89.8033 19.3215 89.5999 18.4133 89.6135 16.963C89.6135 14.5639 90.4268 12.5986 92.0804 10.9314C93.8424 9.1558 95.7264 8.3561 98.1933 8.3561C100.62 8.3561 102.463 9.11514 104.171 10.7959C105.96 12.5579 106.787 14.5368 106.773 16.963C106.773 18.5082 106.516 19.6468 105.892 20.8802C104.428 23.7672 101.758 25.5157 98.5999 25.6377C97.8951 25.6648 97.0412 25.6377 96.7023 25.5835ZM100.254 21.4766C101.229 21.0293 102.178 20.0805 102.639 19.091C102.951 18.4269 103.005 18.1422 103.005 17.0308C103.005 15.8922 102.964 15.6483 102.612 14.9028C102.138 13.8727 101.202 12.951 100.118 12.4495C99.4674 12.1513 99.1557 12.0835 98.1933 12.0835C97.231 12.0835 96.9192 12.1513 96.2686 12.4495C95.1978 12.951 94.3439 13.7778 93.8153 14.8486C93.4222 15.6618 93.3816 15.8516 93.3816 17.0308C93.3816 18.1694 93.4222 18.4133 93.7611 19.091C94.1813 19.9314 95.1165 20.9751 95.8078 21.3275C97.1361 22.0188 98.9252 22.0865 100.254 21.4766Z"
          fill="url(#paint7_linear_6001_2185)"
        />
        <path
          d="M109.918 5.90279C108.82 5.57749 107.912 4.31695 107.898 3.05641C107.857 0.359122 111.07 -1.02341 112.967 0.887736C114.174 2.09406 114.241 3.77478 113.13 4.99466C112.29 5.9299 111.11 6.2552 109.918 5.90279ZM112.127 5.2793C112.425 5.11665 112.791 4.75069 113.022 4.38472C113.781 3.15129 113.442 1.63322 112.249 0.847074C111.748 0.521773 111.571 0.48111 110.785 0.521773C109.728 0.575989 109.077 0.982616 108.63 1.85009C107.491 4.08653 109.891 6.43141 112.127 5.2793Z"
          fill="url(#paint8_linear_6001_2185)"
        />
        <path
          d="M110.053 4.26273C110.013 4.1543 109.985 3.51725 110.013 2.8531L110.034 2.23069C110.045 1.89889 110.305 1.62921 110.636 1.60611C111.436 1.55189 111.883 1.85008 111.883 2.43292C111.883 2.67689 111.815 2.94798 111.72 3.04286C111.598 3.16484 111.626 3.31394 111.815 3.6799C112.005 4.01876 112.032 4.19496 111.937 4.28984C111.829 4.39828 111.666 4.28984 111.354 3.89677C110.826 3.23261 110.528 3.1784 110.528 3.73412C110.528 4.18141 110.175 4.57448 110.053 4.26273ZM111.273 2.52779C111.273 2.25671 111.192 2.17539 110.907 2.14828C110.568 2.10761 110.528 2.14828 110.528 2.52779C110.528 2.90731 110.568 2.94798 110.907 2.90731C111.192 2.8802 111.273 2.79888 111.273 2.52779Z"
          fill="url(#paint9_linear_6001_2185)"
        />
        <path
          d="M1.70087 5.23864C0.548759 4.73713 0.00659028 3.85611 0.0879156 2.59556C0.155687 1.55189 0.61653 0.860624 1.52466 0.467552C4.35749 -0.79299 6.47195 3.2055 3.9102 4.954C3.42225 5.29285 2.20237 5.4555 1.70087 5.23864Z"
          fill="url(#paint10_linear_6001_2185)"
        />
        <path
          d="M82.1316 5.11664C81.2505 4.72357 80.5728 3.70701 80.5728 2.79887C80.5728 2.31092 80.8846 1.53833 81.2641 1.1046C82.4433 -0.223715 84.6798 0.0473692 85.4388 1.6061C86.103 3.00219 85.5743 4.46604 84.1647 5.11664C83.4734 5.44195 82.8228 5.44195 82.1316 5.11664Z"
          fill="url(#paint11_linear_6001_2185)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_6001_2185"
        x1="3.58273"
        y1="-1.78125e-07"
        x2="6.28212"
        y2="42.5988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
    </defs>
  </svg>
</template>
